
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import Editable from '@/mixins/Editable';
import VWrapper from '@/components/shared/VWrapper.vue';
import { namespace } from 'vuex-class';
import { Venue } from '@/interfaces/models/Venue';
import { PrintGroups } from '@/interfaces/models/PrintGroups';
import EditPrintGroupsLoader from '../../components/printGroups/EditPrintGroupsLoader.vue';
import Notification from '@/mixins/Notification';
import PrintGroupsForm from '@/components/printGroups/PrintGroupsForm.vue';

const venue = namespace('venue');
const printGroups = namespace('printGroups');

@Component({
  components: { PrintGroupsForm, EditPrintGroupsLoader, VWrapper },
})
export default class EditPrintGroups extends mixins(Editable, Notification) {
  @venue.State('active') public venue!: Venue;

  @printGroups.Action('store') public store!: any;
  @printGroups.Action('update') public update!: any;
  @printGroups.Action('show') public show!: any;
  @printGroups.State('active') public active!: PrintGroups;

  public $refs!: {
    form: InstanceType<typeof PrintGroupsForm> & { getData: () => any; validate: any };
  };

  get title() {
    return this.editing ? 'printGroups.edit' : 'printGroups.create';
  }

  get printGroups() {
    if (this.editing && this.active) {
      return this.active;
    }

    return null;
  }

  public async mounted() {
    if (this.editing) {
      this.$startLoading('printGroups');
      await this.show({ id: this.id });
      this.$stopLoading('printGroups');
    }
    if (this.editing && !this.active) {
      this.$router.push({ name: 'printGroups.index' });
    }
    if (!this.editing && !this.venue) {
      this.$router.push({ name: 'printGroups.index' });
    }
  }

  public save() {
    this.$refs.form.validate().then(async (res: boolean) => {
      if (res) {
        if (this.editing) {
          await this.update({ ...this.$refs.form.getData(), id: this.id });
        } else {
          await this.store({ ...this.$refs.form.getData(), venue: this.venue._id });
        }
        this.$router.push({ name: 'printGroups.index' });
      } else {
        this.notifyError('notification.form');
      }
    });
  }
}
